<template>
    <div class="row g-xxl-9">
        <div class="col-md-9 col-lg-9" id="redirect_left_div">
            <div class="bg-white p-6 rounded-xl">
                <h2 class="mb-1"> Quiz Scoring </h2>
                <p class="font-semibold text-gray-500">{{ max }} possible Points</p>

                <!-- <pre class="text-black" >{{ poweTypeQuestion }}</pre> -->
                <div class="row g-xxl-6 pt-1 mt-2" v-for="(scoreData, index) in scoring" :key="index">
                    <div class="col-md-3 col-lg-3">
                        <div class="d-flex align-items-center title-group">
                            <input type="text" v-if="scoreData.isInput"
                                class="outline-0 score-name font-semibold text-gray-400" placeholder="Score Name"
                                v-model="scoreData.name">
                            <p class="outline-0 font-semibold text-gray-400 mb-0 line-clamp-1" v-else>{{
                    scoreData.name }}
                            </p>

                            <svg xmlns="http://www.w3.org/2000/svg" class="w-3 ms-3 cursor-pointer pen" viewBox="0 0 512 512"
                                v-if="!scoreData.isInput" @click="scoreData.isInput = true">
                                <path
                                    d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" v-else class="w-4 ms-4" viewBox="0 0 448 512"
                                @click="scoreData.isInput = false, editQuizScoreDebounce(scoreData , index)">
                                <path
                                    d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                        </div>

                        <div class="row g-xxl-4 mt-2">
                            <div class="col-md-6 col-lg-6">
                                <input type="number" class="form-control py-5 rounded-0" placeholder="Min 25"
                                    v-model="scoreData.min" @input="editQuizScoreDebounce(scoreData , index)">
                                    <p class="text-red-500 mt-1" v-if="isMaxIsInvalid == index && !isForMax" >{{ maxValidationMassege }}</p>
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <input type="number" class="form-control py-5 rounded-0" placeholder="Enter Max #"
                                    v-model="scoreData.max" @input="editQuizScoreDebounce(scoreData , index)">
                                <p class="text-red-500 mt-1" v-if="isMaxIsInvalid == index && isForMax" >{{ maxValidationMassege }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8 col-lg-8">
                        <p class="text-black text-lg text-gray-500 invisible">Defination</p>
                        <input type="text" class="form-control py-5 mt-5 rounded-0" v-model="scoreData.description"
                            placeholder="Defination" @input="editQuizScoreDebounce(scoreData , index)">
                    </div>
                    <div class="col-md-1 col-lg-1 align-items-end d-flex opacity-20 cursor-pointer" :class="isMaxIsInvalid == index ? 'mb-16' : 'mb-4'">
                        <svg xmlns="http://www.w3.org/2000/svg" @click="removeScore(index, scoreData.id)"
                            viewBox="0 0 384 512" class="w-5">
                            <path
                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                        </svg>
                    </div>
                </div>
                <p class="text-red-500 mt-1" v-if="isMaxPossibleScore" >{{ maxValidationMassege }}</p>
                <p class="text-black mt-8 mb-0 cursor-pointer" @click="addScore(scoring.length - 1)">Add Additional</p>
            </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-12 bg-white rounded-lg" id="redirect-option" v-if="isDisQualiScore">
            <div class="card-header py-6 px-2">
                <!-- <pre class="text-black" >{{ scoring }}</pre> -->
                <div class="d-flex align-items-center justify-content-between">

                    <div class="d-flex align-items-center flex-wrap gap-1">
                        <h3 class="card-title fw-bolder align-items-start flex-column mb-0">Disqualifying Score</h3>
                        <img class="ml-2" :src="require('@/assets/images/img/question_mark_icon.svg')">
                    </div>
                    <div class="card-toolbar">
                        <div class="d-flex align-items-center gap-1">
                            <!-- <div class="d-flex justify-content-between align-items-center form-check form-check-solid form-switch fv-row ps-0">
                                <label class="form-check-label h3 text-white" for="logo-bar"></label>
                                <input class="custom-checked form-check-input w-45px h-30px" type="checkbox" @change="changeScoreStatusDebounce" id="logo-bar" v-model="scoreStatus">
                            </div> -->


                            <span class="svg-icon svg-icon-primary svg-icon-2x show-pointer"
                                @click="isDisQualiScore = false"> 
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24px" height="24px" viewBox="0 0 24 24" stroke="none" version="1.1">
                                    <g stroke="#A1A5B7" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                                            fill="#A1A5B7">
                                            <rect x="0" y="7" width="16" height="2" rx="1"></rect>
                                            <rect opacity="0.3"
                                                transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                                                x="0" y="7" width="16" height="2" rx="1"></rect>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body px-2">
                <p class="text-black text-lg">Select which score name is considered disqualiying. Every score below
                    that will also be considered as disqualified.</p>

                <select class="form-select bg-light">
                    <option disabled>Select Score</option>
                    <option v-for="(scoreData, index) in scoring" :key="index" :value="scoreData.name">{{
                    scoreData.name }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import _ from "lodash";
import { useToast } from "vue-toastification";
import { defineComponent, toRefs } from "vue";
export default defineComponent({
    name: "Score-box",
    props: {
        quizData: {
            type: Object,
        },
        quizId: Number,
    },
    setup(props) {
        const { quizData: quiz } = toRefs(props);
        const toasts = useToast();
        return {
            quiz,
            toasts
        };
    },
    data() {
        return {
            scoring: [],
            isDisQualiScore: true,
            scoreStatus : false,
            poweTypeQuestion : [],
            max : 0,
            min : 0,
            allowType : ['dropdown-question', 'image-options-question', 'options-question', 'yes-no-question'],
            maxValidationMassege : 'required',
            isMaxIsInvalid : null,
            isForMax : true,
            isMaxPossibleScore : false,
        }
    },
    methods: {
        ...mapActions({
            saveQuizScore: 'Quiz/saveQuizScore',
            getQuizScore: 'Quiz/getQuizScore',
            removeQuizScore: 'Quiz/removeQuizScore',
            changeScoreStatus: 'Quiz/changeScoreStatus',
            setQuestions : 'Quiz/setQuestions'
        }),
        editQuizScoreDebounce: _.debounce(function (obj , index) {
                this.isMaxPossibleScore = null
                if (obj.min < this.min) {
                    this.isMaxIsInvalid = index;
                    this.isForMax = false;
                    this.maxValidationMassege = `Greater than ${this.min} value`
                    return
                }else if (!obj.max) {
                    this.maxValidationMassege = "required"
                    this.isForMax = true;
                    this.isMaxIsInvalid = index;
                    return
                }else if (obj.max <= obj.min) {
                    this.isMaxIsInvalid = index;
                    this.isForMax = true;
                    this.maxValidationMassege = "Greater than min value"
                    return
                }else if (obj.max > this.max) {
                    this.isMaxIsInvalid = index;
                    this.isForMax = true;
                    this.maxValidationMassege = `Less than ${this.max} value`
                    return
                }
                this.isMaxIsInvalid = null

            let payload = {
                quiz_id: this.quiz.id, ...obj
            }

            this.saveQuizScore(payload)
                .then()
                .catch(
                    (error) => {
                        this.toast.error(error.message);
                    }
                )
        }, 800),
        changeScoreStatusDebounce: _.debounce(function () {
            let payload = {
                id : this.quiz.id,
                status : this.scoreStatus
            }
            this.changeScoreStatus(payload)
                .then(
                    (res) => {
                        this.toasts.success(res.data.message);
                    }
                )
                .catch(
                    (error) => {
                        this.toast.error(error.message);
                    }
                )
        }),
        addScore(length) {
            if (this.scoring[length]) {
                if (!this.scoring[length].max) {
                    this.isMaxIsInvalid = length;
                    return
                }else if (this.scoring[length].max <= this.scoring[length].min) {
                    this.isMaxIsInvalid = length;
                    this.maxValidationMassege = "Greater than min value"
                    return
                }
                this.isMaxIsInvalid = null
            }

            if (this.scoring[length] && this.scoring[length].max && this.scoring[length].max >= this.max) {
                    this.isMaxPossibleScore = true;
                    this.maxValidationMassege = `You have already set max possible score`
                    return
            }
            this.isMaxPossibleScore = null

            let payload = {
                quiz_id: this.quiz.id,
                name: (Number(length) + 2) == 1 ? "Score Name" : "Score Name " + (Number(length) + 2),
                min: this.scoring[length] ? this.scoring[length].max + 1 : this.min,
                max: null,
                description: null,
            }

            this.saveQuizScore(payload)
                .then(
                    (res) => {
                        if (res.data.data) {
                            let data = res.data.data
                            this.scoring.push({
                                id : data.id,
                                name: data.name,
                                isInput: false,
                                min: data.min,
                                max: data.max,
                                description: data.description,
                            })
                        }
                    }
                )
                .catch(
                    (error) => {
                        this.toast.error(error.message);
                    }
                )
        },
            removeScore(index, id) {

            if(id) {
                this.removeQuizScore(id)
                    .then(
                        (res) => {
                            this.toasts.success(res.data.message);
                            this.scoring.splice(index, 1)
                        }
                    )
                    .catch(
                        (error) => {
                            this.toast.error(error.message);
                        }
                    )
            }
        },
        getallQuestion(type='all'){
            this.showloader=true
            let data={
                type:type,
                quiz_id:this.quizData.id
            }
            this.setQuestions(data).then((res)=>{
            // console.log(res.data.data.result,'ress')
                if(res.data.status==true){
                    let question = res.data.data.result;
                    this.poweTypeQuestion = question.filter(curr => this.allowType.includes(curr.type))

                    this.poweTypeQuestion.map(
                        (que) => {
                            if (que.inputs  && que.inputs.length) {
                                let point = que.inputs.map(
                                    (inp) => {
                                        return inp.score_point
                                    }
                                )
                                this.max += Math.max(...point);
                                this.min += Math.min(...point);
                            }
                        }
                    )
                }
            }).catch((error)=>{
                this.toast.error(error.message);
            })
        },
    },
    mounted() {
        this.getallQuestion();
        this.scoreStatus = this.quiz.score_redirect_status == 1
        this.getQuizScore({ quiz_id: this.quiz.id })
            .then(
                (res) => {
                    if (res.data.data.length) {
                        let data = res.data.data
                        this.scoring = data.map((curr) => {
                            curr.isInput = false
                            return curr
                        })
                    }
                }
            )
            .catch(
                (error) => {
                    this.toast.error(error.message);
                }
            )
    },

});
</script>

<style scoped>
.score-name {
    border: none;
    border-bottom: 3px dotted #bbb;
    max-width: 100px;
}

.form-check-input {
    border: 0;
    background-color: #3d3f4c;
}
.form-check-input:checked {
    background-color: #e49c35;
}

.custom-checked{
    width: 40px !important; 
    height: 23px !important;
}

.title-group:hover .pen {
    visibility: visible;
}

.pen {
    visibility: hidden;
}
</style>

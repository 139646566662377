<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
    <div class="box p-0">
        <div class="custom-editor-tab">
            <i class="fa fa-images fa-lg"  @click="tabIndex = 0" :class="tabIndex == 0 ? 'text-success' : ''"></i>
            <svg
                width="24" height="24"
                viewBox="0 0 19 17" :fill="tabIndex === 1 ? '#2477FF' : '#A7A8AB'"
                xmlns="http://www.w3.org/2000/svg"
                class="cursor-pointer"
                @click="tabIndex = 1"
            >
            <path
                d="M17 10C17 10 15 12.9062 15 14C15 15.125 15.875 16 17 16C18.0938 16 19 15.125 19 14C19 12.9062 17 10 17 10ZM16.6875 6.8125L10.1875 0.3125C10.0312 0.125 9.78125 0 9.5 0C9.25 0 8.96875 0.125 8.78125 0.3125L6.0625 3.03125L3.1875 0.15625C3 -0.03125 2.6875 -0.03125 2.46875 0.15625L2.125 0.5C1.9375 0.71875 1.9375 1.03125 2.125 1.21875L5 4.09375L1.875 7.21875C0.6875 8.40625 0.6875 10.3125 1.875 11.4688L5.53125 15.125C6.125 15.7188 6.875 16.0312 7.65625 16.0312C8.40625 16.0312 9.1875 15.7188 9.78125 15.125L16.6875 8.21875C17.0938 7.8125 17.0938 7.1875 16.6875 6.8125ZM8.71875 14.0625C8.4375 14.375 8.0625 14.5 7.65625 14.5C7.25 14.5 6.875 14.375 6.59375 14.0625L3.03125 10.5H12.2812L8.71875 14.0625ZM13.7812 9H2.53125C2.59375 8.75 2.71875 8.5 2.9375 8.28125L6.0625 5.15625L8.25 7.34375C8.53125 7.625 9 7.625 9.3125 7.34375C9.59375 7.03125 9.59375 6.5625 9.3125 6.28125L7.125 4.09375L9.5 1.71875L15.2812 7.5L13.7812 9Z"/>
            </svg>
        </div>
        <div class="custom-editor-tab-body">
            <!-- <pre>{{ library }}</pre> -->
            <div v-show="tabIndex == 0">
                <div class="d-flex justify-content-between align-items-center">
                    <h3 class="mb-7 h3">Image Library</h3>
                </div>

                <div class="d-flex justify-content-between mb-7">
                    <div class="d-flex">
                        <span class="form-check form-check-custom form-check-solid">
                            <input class="form-check-input w-20px h-20px" type="radio" name="image_type" id="collection" v-model="library.image_type" @change="updateLibrarySettingDebounce" value="collection">
                            <label class="form-label m-0 ms-4 me-4" for="collection">
                                Collection
                            </label>
                        </span>

                        <span class="form-check form-check-custom form-check-solid">
                            <input class="form-check-input w-20px h-20px" type="radio"
                                name="image_type" v-model="library.image_type" @change="updateLibrarySettingDebounce" id="custom" value="custom">
                            <label class="form-label m-0 ms-4 me-4" for="custom">
                                Custom
                            </label>
                        </span>
                    </div>
                </div>

                <section class="collection" v-show="library.image_type == 'collection'">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-5">
                                <span for="seach_images" class="form-label fw-bolder">Search Images</span>
                                <div class="d-flex mt-2">
                                    <input
                                        id="seach_images"
                                        type="text"
                                        class="form-control form-control-solid form-control-sm me-3"
                                        v-model="filter.query"
                                        @keyup.enter="searchImageDebounce"
                                    />
                                    <button class="btn btn-icon btn-custom btn-active-color-primary btn-sm" @click="searchImageDebounce">Go</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="col-sm-12 col-md-6 mb-7"
                            v-for="(image, index) in getImagesData"
                            :key="index"
                            @click="updateImageData(image)"
                        >
                            <img :src="getImagesThumbnail(image)" :alt="image.alt" class="rounded image-box" :class="library.image_id && image.id == library.image_id ? 'active' : ''"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-4" v-if="filter.page > 1" @click="previousPage">
                                <span class="svg-icon svg-icon-3 cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24"/>
                                            <path d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z" fill="#000000" fill-rule="nonzero" transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) "/>
                                            <path d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) "/>
                                        </g>
                                    </svg>
                                </span>
                            </a>
                            <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"  @click="nextPage">
                                <span class="svg-icon svg-icon-3 cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24"/>
                                            <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero"/>
                                            <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "/>
                                        </g>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </section>

                <section v-show="library.image_type == 'custom'">
                    <div class="row">
                        <div class="col-md-6 col-xs-12 col-sm-12">
                            <input type="file" class="d-none" ref="imageInput" @change="onFileChange($event)">
                                <div class="image-block">
                                <div class="image-block-edit-icon" @click="$refs.imageInput.click()">
                                    <i class="fa fa-pen" aria-hidden="true"></i>
                                </div>
                                <img
                                    class="w-100 h-100 image-radious"
                                    v-if="uploadedImage"
                                    :src="uploadedImageUrl"
                                >
                                <img
                                    class="w-100 h-100 image-radious"
                                    v-else :src="imageSrc"
                                >
                            </div>
                        </div>
                    </div>
                </section>
              
            </div>
            <div v-if="tabIndex == 1">
                <div class="d-flex justify-content-between align-items-center">
                    <h3 class="mb-7 h3">Image Style</h3>
                </div>

                <div class="row mt-5">
                    <div class="col-md-6 col-xs-12 col-sm-12">
                        <label for="" class="form-label">Image Overlay</label>
                        <div class="input-group mb-3" v-click-outside="closeOverlayColorPicker">
                            <input type="text" class="form-control form-control-solid" v-model="overlayColor.hex8">
                            <div class="input-group-append" @click="isOverlayColorPicker = !isOverlayColorPicker">
                                <span class="input-group-text" id="basic-addon2">
                                    <div class="bg-white">
                                        <div class="rect" :style="{ backgroundColor: overlayColor.hex8 }"></div>
                                    </div>
                                </span>
                            </div>
                            <Sketch
                                :class="{ colorpicker: true, 'd-none': !isOverlayColorPicker }"
                                v-model="overlayColor"
                                :preset-colors="['#ffffff','#efefef','#000000','#201652','#0075B2','#FFE300','#42B99F','#CC0000']"
                            ></Sketch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
/* eslint-disable */
import {defineComponent,toRefs} from "vue";
import { Sketch } from '@ckpack/vue-color';
import vClickOutside from 'click-outside-vue3'
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";


export default defineComponent({
    directives: {
      clickOutside: vClickOutside.directive
    },
  props:{
    quiz_data:{
      type:Object
    },
    questions_data:{
      type: Array
    },
  },
components: {Sketch},
    setup(props) {
        const {quiz_data: quizData} = toRefs(props);
        const {questions_data: questions} = toRefs(props);
        const toast = useToast();
        return {quizData,questions,toast};
    },
    data() {
        return {
            filter: {
                page: 1,
                per_page: 6,
                query: "Ocean",
            },
            library: {},
            tabIndex: 0,
            uploadedImage: null,
            uploadedImageUrl: null,
            assetUrl : process.env.VUE_APP_ENVIROMENT != 'local' ? process.env.VUE_APP_API_URL : "http://127.0.0.1:8000",
            overlayColor : {},
            isOverlayColorPicker : false,
        }
    },
    watch: {
        overlayColor(){
            this.updateLibrarySettingColor()
            this.updateLibrarySettingDebounce()
        },
    },
    computed: {
        ...mapGetters({
            getImagesData: "Pexels/getImagesData",
            getCurrentQuestion: "Quiz/getCurrentQuestion",
        }),
        imageSrc() {
            if (this.library.upload_url && this.library.upload_url.search('blob:') == -1) {
                return this.assetUrl + '/storage/' + this.library.upload_url
            } else if (this.library.upload_url) {
                return this.library.upload_url
            } else {
                return require('@/assets/images/img/no-image.png')
            }
        },  
    },
    mounted() {
        this.searchImages(this.filter);
        this.setLibraryData()
    },
    created() {
    },
    methods: {
        ...mapActions({
            updateQuestionImageData: "Quiz/updateQuestionImageData",
            updateLibraryImage: "Quiz/updateLibraryImage",
            updateLibrarySetting: "Quiz/updateLibrarySetting",
            searchImages: "Pexels/searchImages",
        }),
        searchImageDebounce: _.debounce(function () {
            this.filter.page = 1
            this.searchImages(this.filter)
        }, 0),
        updateImageData(image){
            if(this.getCurrentQuestion){
                var imageUrl = image.src.original
                if(imageUrl){
                    this.library.image_id = image.id
                    this.library.image_url = imageUrl;
                    var currentIndex = this.questions.findIndex(q => q.id == this.getCurrentQuestion.id);
                    if(currentIndex != -1){
                        if(this.questions[currentIndex] && this.questions[currentIndex].library){
                                this.questions[currentIndex].library.image_id = image.id;
                                this.questions[currentIndex].library.image_url = imageUrl;
                        }else{
                            this.questions[currentIndex].library = this.library
                        }
                    }
                    this.updateQuestionImageData(this.library).then((response)=>{
                        if(response.data.status==true){
                            this.toast.success('image url updated')
                        }
                    })
                }else{
                    this.toast.warning('This image not available,choose diffrent one')
                }
            }
        },
        nextPage(){
            this.filter.page += 1
            this.searchImages(this.filter)
        },
        previousPage(){
            this.filter.page -= 1
            this.searchImages(this.filter)
        },
        getImagesThumbnail(image){
            var imageData = image.src && image.src.tiny ? image.src.tiny : null
            return imageData
        },
        setLibraryData(){
            var question = this.questions.find(q => q.id == this.getCurrentQuestion.id);
            if(question && question.library){
                this.library = question.library
            }else{
                this.library = {
                    id : null,
                    question_id: this.getCurrentQuestion.id,
                    quiz_id: this.getCurrentQuestion.quiz_id,
                    image_type : 'collection',
                    image_id : null,
                    image_url : null,
                    upload_url : null,
                    video_id : null,
                    video_url : null,
                    is_image_overlay : 1,
                    image_overlay : null
                }
            }

            if (this.library && this.library.image_overlay) {
                let overlayData = {
                    hex8: this.library.image_overlay
                }
                this.overlayColor = overlayData
            }
        },
        onFileChange(e) {
            const file = e.target.files[0];
            if (file) {
                if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/svg' || file.type == 'image/svg+xml') {
                    this.uploadedImage = file
                    this.uploadedImageUrl = URL.createObjectURL(file);
                    this.library.upload_url = this.uploadedImageUrl
                    // this.updateLibraryImage(type)
                    var formdata= new FormData();
                    formdata.append('quiz_id',this.library.quiz_id)
                    formdata.append('question_id',this.library.question_id)
                    formdata.append('upload_url',this.uploadedImage)
                    this.updateLibraryImage(formdata)
                } else {
                    this.$refs.imageInput.value = ''
                    this.$swal.fire(
                        "Error!",
                        "Please enter valid image file.",
                        "error"
                    );
                }
            }
        },
        updateLibrarySettingDebounce: _.debounce(function () {
            this.library.image_overlay = this.overlayColor.hex8
            this.updateLibrarySetting(this.library)
        }, 700),
        closeOverlayColorPicker(){
            this.isOverlayColorPicker = false
        },
        updateLibrarySettingColor(){
            this.library.image_overlay = this.overlayColor.hex8
        },
    },
})
</script>

<style scoped>

.custom-checked{
    width: 40px !important; 
    height: 23px !important;
}
.box {
  background-color: #2D2F3A;
  border-radius: 5px;
  padding: 15px;
}

.custom-editor-tab {
  color: lightgrey;
  padding: 13px;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-around;
}
.custom-editor-tab-body {
  background-color: #343542;
  height: 93.2%;
  border-radius: 0 0 5px 5px;
  padding: 20px 15px;
  min-height: calc(100vh - 260px);
}
.custom-editor-tab-body h3 {
  color: white;
  font-weight: 400;
}
.custom-tabs {
  color: lightgrey;
  display: flex;
  align-items: center;
  gap: 15px;
}

.custom-tabs .active {
  color: white;
  font-weight: 600;
}

.action-box {
  display: flex;
  align-items: center;
  gap: 40px;
}

.form-check {
  min-height: 0;
}

.vc-input__input{
    color: #202020 !important;
}

.rect{
    width: 23px;
    height: 23px;
}
.input-group-text{
    border: 0;
    background-color: #2D2F3A;
    border-radius: 0 0.475rem 0.475rem 0;
}

.image-block {
  height: 90px;
  border-radius: 6px;
  position: relative;
}

.image-block-edit-icon {
  position: absolute;
  color: #11132d;
  right: 10px;
  background: white;
  font-size: 16px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  padding-left: 6px;
  padding-top: 6px;
  top: 7px;
}

.image-radious {
  border-radius: 10px;
}

.bgCover101 {
  background-size: 100% 100% !important;
  -webkit-background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}

.bgCover {
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-attachment: fixed !important;
  background-repeat: repeat repeat !important;
  background-position: center center;
}

.bgCover100 {
  background-size: 100% auto !important;
  -webkit-background-size: 100% auto !important;
  background-repeat: no-repeat !important;
}

.bgNoRepeat {
  background-repeat: no-repeat !important;
}

input.vc-input__input{
    color: #202020 !important;
}
.custom-size{
    background-color: #2d2f3a;
    border-radius: 4px;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}
.form-label{
    color: #e4e6ef;
}
.suffle-button{
    background-color: #3D3F4C;
    color: #e4e6ef;
}
.form-check.form-check-solid .form-check-input {
    border: 0;
    background-color: #3d3f4c;
}
.form-check.form-check-solid .form-check-input:checked {
    background-color: #e49c35;
}
.form-control.form-control-solid, .form-select-solid {
    background-color: #3d3f4c;
    border-color: #3d3f4c;
    color: #c4c7cc;
    transition: color .2s ease,background-color .2s ease;
}
.dropdown.show > .form-select.form-select-solid, .form-select.form-select-solid:active, .form-select.form-select-solid.active, .form-select.form-select-solid:focus, .form-select.form-select-solid.focus {
    background-color: #3d3f4c;
    border-color: #3d3f4c;
    color: #c4c7cc;
    transition: color 0.2s ease, background-color 0.2s ease;
}
.btn-custom{
    background-color: #2D2F3A !important;
    color: #c4c7cc;
    width: 60px !important;
}

.image-box{
    width: 100%;
    height: 95px;
}

.active{
    border: 1px solid #e49c35;
}
.vc-sketch{
    position: absolute;
    z-index: 10000;
}
</style>
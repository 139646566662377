<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
    <div class="box p-0">
        <div class="custom-editor-tab">
            <svg
                width="24" height="24"
                viewBox="0 0 19 17" :fill="tabIndex === 0 ? '#2477FF' : '#A7A8AB'"
                xmlns="http://www.w3.org/2000/svg"
                class="cursor-pointer"
                @click="tabIndex = 0"
            >
            <path
                d="M17 10C17 10 15 12.9062 15 14C15 15.125 15.875 16 17 16C18.0938 16 19 15.125 19 14C19 12.9062 17 10 17 10ZM16.6875 6.8125L10.1875 0.3125C10.0312 0.125 9.78125 0 9.5 0C9.25 0 8.96875 0.125 8.78125 0.3125L6.0625 3.03125L3.1875 0.15625C3 -0.03125 2.6875 -0.03125 2.46875 0.15625L2.125 0.5C1.9375 0.71875 1.9375 1.03125 2.125 1.21875L5 4.09375L1.875 7.21875C0.6875 8.40625 0.6875 10.3125 1.875 11.4688L5.53125 15.125C6.125 15.7188 6.875 16.0312 7.65625 16.0312C8.40625 16.0312 9.1875 15.7188 9.78125 15.125L16.6875 8.21875C17.0938 7.8125 17.0938 7.1875 16.6875 6.8125ZM8.71875 14.0625C8.4375 14.375 8.0625 14.5 7.65625 14.5C7.25 14.5 6.875 14.375 6.59375 14.0625L3.03125 10.5H12.2812L8.71875 14.0625ZM13.7812 9H2.53125C2.59375 8.75 2.71875 8.5 2.9375 8.28125L6.0625 5.15625L8.25 7.34375C8.53125 7.625 9 7.625 9.3125 7.34375C9.59375 7.03125 9.59375 6.5625 9.3125 6.28125L7.125 4.09375L9.5 1.71875L15.2812 7.5L13.7812 9Z"/>
            </svg>

            <svg
                width="24" height="24"
                viewBox="0 0 15 15" fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="tabIndex = 1"
            >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.78233 2.21713C3.70732 2.14212 3.60557 2.09998 3.49949 2.09998C3.3934 2.09998 3.29166 2.14212 3.21664 2.21713L1.21664 4.21713C1.06044 4.37334 1.06044 4.62661 1.21664 4.78282C1.37285 4.93903 1.62612 4.93903 1.78233 4.78282L3.09949 3.46566L3.09949 11.5343L1.78233 10.2171C1.62612 10.0609 1.37285 10.0609 1.21664 10.2171C1.06043 10.3733 1.06043 10.6266 1.21664 10.7828L3.21664 12.7828C3.29166 12.8578 3.3934 12.9 3.49949 12.9C3.60557 12.9 3.70731 12.8578 3.78233 12.7828L5.78233 10.7828C5.93854 10.6266 5.93854 10.3733 5.78233 10.2171C5.62612 10.0609 5.37285 10.0609 5.21664 10.2171L3.89949 11.5343L3.89949 3.46566L5.21664 4.78282C5.37285 4.93903 5.62612 4.93903 5.78233 4.78282C5.93854 4.62661 5.93854 4.37334 5.78233 4.21713L3.78233 2.21713ZM8.49998 3.99997C8.22383 3.99997 7.99998 4.22382 7.99998 4.49997C7.99998 4.77611 8.22383 4.99997 8.49998 4.99997H14.5C14.7761 4.99997 15 4.77611 15 4.49997C15 4.22382 14.7761 3.99997 14.5 3.99997H8.49998ZM7.99998 7.49997C7.99998 7.22382 8.22383 6.99997 8.49998 6.99997H14.5C14.7761 6.99997 15 7.22382 15 7.49997C15 7.77611 14.7761 7.99997 14.5 7.99997H8.49998C8.22383 7.99997 7.99998 7.77611 7.99998 7.49997ZM8.49998 9.99997C8.22383 9.99997 7.99998 10.2238 7.99998 10.5C7.99998 10.7761 8.22383 11 8.49998 11H14.5C14.7761 11 15 10.7761 15 10.5C15 10.2238 14.7761 9.99997 14.5 9.99997H8.49998Z"
                :fill="tabIndex == 1 ? '#2477FF' : '#A7A8AB'"
            />
            </svg>

            <!-- <svg
                xmlns="http://www.w3.org/2000/svg"
                :fill="tabIndex === 3 ? '#2477FF' : '#A7A8AB'"
                viewBox="0 0 50 50"
                width="24" height="24"
                class="cursor-pointer"
                @click="tabIndex = 3"
            >
            <path
                d="M47.16,21.221l-5.91-0.966c-0.346-1.186-0.819-2.326-1.411-3.405l3.45-4.917c0.279-0.397,0.231-0.938-0.112-1.282 l-3.889-3.887c-0.347-0.346-0.893-0.391-1.291-0.104l-4.843,3.481c-1.089-0.602-2.239-1.08-3.432-1.427l-1.031-5.886 C28.607,2.35,28.192,2,27.706,2h-5.5c-0.49,0-0.908,0.355-0.987,0.839l-0.956,5.854c-1.2,0.345-2.352,0.818-3.437,1.412l-4.83-3.45 c-0.399-0.285-0.942-0.239-1.289,0.106L6.82,10.648c-0.343,0.343-0.391,0.883-0.112,1.28l3.399,4.863 c-0.605,1.095-1.087,2.254-1.438,3.46l-5.831,0.971c-0.482,0.08-0.836,0.498-0.836,0.986v5.5c0,0.485,0.348,0.9,0.825,0.985 l5.831,1.034c0.349,1.203,0.831,2.362,1.438,3.46l-3.441,4.813c-0.284,0.397-0.239,0.942,0.106,1.289l3.888,3.891 c0.343,0.343,0.884,0.391,1.281,0.112l4.87-3.411c1.093,0.601,2.248,1.078,3.445,1.424l0.976,5.861C21.3,47.647,21.717,48,22.206,48 h5.5c0.485,0,0.9-0.348,0.984-0.825l1.045-5.89c1.199-0.353,2.348-0.833,3.43-1.435l4.905,3.441 c0.398,0.281,0.938,0.232,1.282-0.111l3.888-3.891c0.346-0.347,0.391-0.894,0.104-1.292l-3.498-4.857 c0.593-1.08,1.064-2.222,1.407-3.408l5.918-1.039c0.479-0.084,0.827-0.5,0.827-0.985v-5.5C47.999,21.718,47.644,21.3,47.16,21.221z M25,32c-3.866,0-7-3.134-7-7c0-3.866,3.134-7,7-7s7,3.134,7,7C32,28.866,28.866,32,25,32z"/>
            </svg> -->
        </div>
        <div class="custom-editor-tab-body">
            <div v-if="tabIndex === 0">
                <!-- <h3 class="mb-4">Customize button</h3> -->
                <div class="d-flex justify-content-between align-items-center form-check form-check-solid form-switch fv-row ps-0">
                    <!-- <label class="form-check-label h3" for="add_customizable_button">Customize button</label> -->
                      <h3 class="mb-4 h3">Customize button</h3>
                    <input class="custom-checked form-check-input w-40px h-25px" type="checkbox" id="add_customizable_button" @change="updateQuizData" v-model="quiz.add_customizable_button">
                </div>

                <div class="mt-10">
                    <div class="mb-10 fv-row fv-plugins-icon-container">
                        <label for="" class="form-label">Button Style</label>
                        <select class="form-select form-select-solid" v-model="button_style" @change="updateQuizTemplateCssData">
                            <option
                                v-for="(button, index) in buttonStyles"
                                :key="index"
                                :value="button.value"
                            >
                                {{ button.name }}
                            </option>
                        </select>
                    </div>

                    <div>
                        <label for="" class="form-label">Font Size</label>
                        <div class="row">
                            <div class="col-9 d-flex align-items-center">
                                <input class="form-range" type="range" v-model="templateCss.font_size" @input="updateQuizTemplateCssData" min="0" max="100" />
                            </div>
                            <div class="col-3">
                                <div class="custom-size">{{templateCss.font_size }} </div>
                            </div>
                        </div>
                    </div>
                    <hr style="background-color: gray" class="my-5"/>

                    <div class="row">
                        <div class="col-md-6 col-xs-12 col-sm-12">
                            <label for="" class="form-label">Text Color</label>
                            <div class="input-group mb-3" v-click-outside="closeTextColorPicker">
                                <input type="text" class="form-control form-control-solid" @input="updateColor('text')"  v-model="textColor.hex8">
                                <div class="input-group-append" @click="isTextColorPicker = !isTextColorPicker">
                                    <span class="input-group-text" id="basic-addon2">
                                        <div class="bg-white">
                                            <div class="rect" :style="{ backgroundColor: textColor.hex8 }"></div>
                                        </div>
                                    </span>
                                </div>
                                <Sketch
                                    :class="{ colorpicker: true, 'd-none': !isTextColorPicker }"
                                    v-model="textColor"
                                    :preset-colors="['#ffffff','#efefef','#000000','#201652','#0075B2','#FFE300','#42B99F','#CC0000']"
                                ></Sketch>
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-12 col-sm-12">
                            <label for="" class="form-label">BG Color</label>
                            <div class="input-group mb-3" v-click-outside="closeBgColorPicker">
                                <input type="text" class="form-control form-control-solid" @input="updateColor('bg')" v-model="bgColor.hex8">
                                <div class="input-group-append" @click="isBgColorPicker = !isBgColorPicker">
                                    <span class="input-group-text" id="basic-addon2">
                                        <div class="bg-white">
                                            <div class="rect" :style="{ backgroundColor: bgColor.hex8 }"></div>
                                        </div>
                                    </span>
                                </div>
                                <Sketch
                                    :class="{ colorpicker: true, 'd-none': !isBgColorPicker }"
                                    v-model="bgColor"
                                    :preset-colors="['#ffffff','#efefef','#000000','#201652','#0075B2','#FFE300','#42B99F','#CC0000']"
                                ></Sketch>
                            </div>
                        </div>
                    </div>

                    <hr style="background-color: gray" class="my-5"/>
                
                </div>
            </div>

            <div v-if="tabIndex === 1">
                <h3 class="mb-10 h3">Spacing</h3>
                <div class="mb-7">
                    <label for="" class="form-label">Spacing Before</label>
                    <div class="row">
                        <div class="col-9 d-flex align-items-center">
                            <input class="form-range" type="range" v-model="templateCss.spacing_before" @input="updateQuizTemplateCssData" min="0" max="100" />
                        </div>
                        <div class="col-3">
                            <div class="custom-size">{{templateCss.spacing_before }} </div>
                        </div>
                    </div>
                </div>
                <div class="mb-7">
                    <label for="" class="form-label">Spacing After</label>
                    <div class="row">
                        <div class="col-9 d-flex align-items-center">
                            <input class="form-range" type="range" v-model="templateCss.spacing_after" @input="updateQuizTemplateCssData" min="0" max="100" />
                        </div>
                        <div class="col-3">
                            <div class="custom-size">{{templateCss.spacing_after }} </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="mb-7">
                    <label for="" class="form-label">Line Height</label>
                    <div class="row">
                        <div class="col-9 d-flex align-items-center">
                            <input class="form-range" type="range" v-model="templateCss.line_height" @input="updateQuizTemplateCssData" min="0" max="100" />
                        </div>
                        <div class="col-3">
                            <div class="custom-size">{{templateCss.line_height }} </div>
                        </div>
                    </div>
                </div> -->
                <div class="mb-7">
                    <label for="" class="form-label">Line Height</label>
                    <select class="form-select form-select-solid" v-model="templateCss.line_height" @input="updateQuizTemplateCssData">
                        <option
                            v-for="(l, index) in lineHeights"
                            :key="index"
                            :value="l.value"
                        >
                            {{ l.name }} {{index > 0 ? 'em' : ''}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import {defineComponent,toRefs} from "vue";
import { Sketch } from '@ckpack/vue-color';
import vClickOutside from 'click-outside-vue3'
import {imagePositions,buttonStyles,lineHeights} from '@/core/helpers/staticData'
import { mapActions } from "vuex";

export default defineComponent({
    directives: {
      clickOutside: vClickOutside.directive
    },
  props:{
    template_css:{
      type:Object
    },
    quiz_data:{
      type:Object
    },
  },
components: {Sketch},
    setup(props) {
        const {quiz_data: quizData} = toRefs(props);
        const {template_css: templateCss} = toRefs(props);
        return {templateCss,quizData};
    },
    data() {
        return {
            tabIndex : 0,
            bgColor : {},
            colors: '#194D33A8',
            isBgColorPicker : false,
            imagePositions : imagePositions,
            full_page_uploaded_image: null,
            full_page_uploaded_image_url: null,
            buttonStyles : buttonStyles,
            lineHeights : lineHeights,
            fontSize :25,
            isTextColorPicker : false,
            isBgColorPicker : false,
            textColor : {},
            bgColor : {},
            quiz: {},
            apiCallStatus : false
        }
    },
    computed : {
        button_style: {
            get() {
                return this.templateCss.style
            },
            set(newValue) {
                this.templateCss.style = newValue
            }
        },
    },
    watch: {
        textColor(){
            this.updateColor('text')
        },
        bgColor(){
            this.updateColor('bg')
        },
    },
    mounted() {
        this.setupQuiz()
        this.setupColors()
        this.updateApiCallStatus()
    },
    methods: {
        ...mapActions({
            updateQuizSetting:'Quiz/updateQuizSetting',
        }),
        setupColors(){
            if (this.templateCss && this.templateCss.text_color) {
                let textData = {
                    hex8: this.templateCss.text_color
                }
                this.textColor = textData
            }
            if (this.templateCss && this.templateCss.bg_color) {
                let bgData = {
                    hex8: this.templateCss.bg_color
                }
                this.bgColor = bgData
            }
        },
        updateColor(type){
            if(this.apiCallStatus){
                if (type == 'text') {
                    this.templateCss.text_color = this.textColor.hex8
                } else if (type == 'bg') {
                    this.templateCss.bg_color = this.bgColor.hex8
                }
                this.updateQuizTemplateCssData()
            }
        },
        closeTextColorPicker(){
            this.isTextColorPicker = false
        },
        closeBgColorPicker(){
            this.isBgColorPicker = false
        },
        updateQuizTemplateCssData: _.debounce(function () {
            this.$emit('updateQuizTemplateCss','button')
        }, 1000),
        setupQuiz(){
            this.quiz = {
                quiz_id : this.quizData.id,
                add_intro_page : !!this.quizData.add_intro_page,
                add_exit_page : !!this.quizData.add_exit_page,
                add_progress_page : !!this.quizData.add_progress_page,
                is_customization_enable : !!this.quizData.is_customization_enable,
                add_progress_bar : !!this.quizData.add_progress_bar,
                add_previous_arrow : !!this.quizData.add_previous_arrow,
                add_logo_bar : !!this.quizData.add_logo_bar,
                add_customizable_button : !!this.quizData.add_customizable_button,
            }
        },
        updateQuizData(){
           this.quizData.add_customizable_button = this.quiz.add_customizable_button
           this.updateQuizDataDB()
        },
        updateQuizDataDB: _.debounce(function () {
            this.updateQuizSetting(this.quiz)
        }, 1000),
        updateApiCallStatus(){
            setTimeout(() => {
                this.apiCallStatus = true
            }, 200);
        }
    },
})
</script>

<style scoped>
.box {
  background-color: #2D2F3A;
  border-radius: 5px;
  padding: 15px;
}

.custom-editor-tab {
  color: lightgrey;
  padding: 13px;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-around;
}
.custom-editor-tab-body {
  background-color: #343542;
  height: 93.2%;
  border-radius: 0 0 5px 5px;
  padding: 20px 15px;
  min-height: calc(100vh - 260px);
}
.custom-editor-tab-body h3 {
  color: white;
  font-weight: 400;
}
.custom-tabs {
  color: lightgrey;
  display: flex;
  align-items: center;
  gap: 15px;
}

.custom-tabs .active {
  color: white;
  font-weight: 600;
}

.action-box {
  display: flex;
  align-items: center;
  gap: 40px;
}

.form-check {
  min-height: 0;
}

.vc-input__input{
    color: #202020 !important;
}

.rect{
    width: 23px;
    height: 23px;
}
.input-group-text{
    border: 0;
    background-color: #2D2F3A;
    border-radius: 0 0.475rem 0.475rem 0;
}

.image-block {
  height: 90px;
  border-radius: 6px;
  position: relative;
}

.image-block-edit-icon {
  position: absolute;
  color: #11132d;
  right: 10px;
  background: white;
  font-size: 16px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  padding-left: 6px;
  padding-top: 6px;
  top: 7px;
}

.image-radious {
  border-radius: 10px;
}

.bgCover101 {
  background-size: 100% 100% !important;
  -webkit-background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}

.bgCover {
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-attachment: fixed !important;
  background-repeat: repeat repeat !important;
  background-position: center center;
}

.bgCover100 {
  background-size: 100% auto !important;
  -webkit-background-size: 100% auto !important;
  background-repeat: no-repeat !important;
}

.bgNoRepeat {
  background-repeat: no-repeat !important;
}

.custom-size{
    background-color: #2d2f3a;
    border-radius: 4px;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}
.form-label{
    color: #e4e6ef;
}
.form-check.form-check-solid .form-check-input {
    border: 0;
    background-color: #3d3f4c;
}
.form-check.form-check-solid .form-check-input:checked {
    background-color: #e49c35;
}
.form-control.form-control-solid, .form-select-solid {
    background-color: #3d3f4c;
    border-color: #3d3f4c;
    color: #c4c7cc;
    transition: color .2s ease,background-color .2s ease;
}
.dropdown.show > .form-select.form-select-solid, .form-select.form-select-solid:active, .form-select.form-select-solid.active, .form-select.form-select-solid:focus, .form-select.form-select-solid.focus {
    background-color: #3d3f4c;
    border-color: #3d3f4c;
    color: #c4c7cc;
    transition: color 0.2s ease, background-color 0.2s ease;
}
.vc-sketch{
    position: absolute;
    z-index: 10000;
}
</style>
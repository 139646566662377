const fontFamilies = [
  {
    name: "Libre Baskerville",
  },
  {
    name: "Open Sans",
  },
  {
    name: "'Courier New', Courier, monospace",
  },
  {
    name: "'Times New Roman', Times, serif",
  },
  {
    name: "Arial, Helvetica, sans-serif",
  },
  {
    name: "'Glyphicons Halflings'",
  },
  {
    name: "cursive",
  },
  {
    name: "'Anton', sans-serif",
  },
  {
    name: "'Anybody', cursive",
  },
  {
    name: "'Changa', sans-serif",
  },
  {
    name: "'Dancing Script', cursive",
  },
  {
    name: "'Domine', serif",
  },
  {
    name: "'Fjalla One', sans-serif",
  },
  {
    name: "'Inter', sans-serif",
  },
  {
    name: "'Lato', sans-serif",
  },
  {
    name: "'Lobster', cursive",
  },
  {
    name: "'Merriweather', serif",
  },
  {
    name: "'Montserrat', sans-serif",
  },
  {
    name: "'Noto Serif', serif",
  },
  {
    name: "'Oswald', sans-serif",
  },
  {
    name: "'Permanent Marker', cursive",
  },
  {
    name: "Poppins",
  },
  {
    name: "'Prompt', sans-serif",
  },
  {
    name: "'Ranga', cursive",
  },
  {
    name: "'Roboto', sans-serif",
  },
  {
    name: "'Rubik Bubbles', cursive",
  },
  {
    name: "'Send Flowers', cursive",
  },
  {
    name: "'Source Sans Pro', sans-serif",
  },
  {
    name: "'Tajawal', sans-serif",
  },
  {
    name: "'Ubuntu', sans-serif",
  },
  {
    name: "'Vazirmatn', sans-serif",
  },
  {
    name: "'PT Sans Narrow', sans-serif",
  },
  {
    name: "'Caveat', cursive",
  },
  {
    name: "system-ui",
  },
  {
    name: "'Raleway', sans-serif",
  },
  {
    name: "'Source Sans Pro', sans-serif",
  },
];

const textTransforms = [
  {
    name: "none",
  },
  {
    name: "capitalize",
  },
  {
    name: "uppercase",
  },
  {
    name: "lowercase",
  },
];

const textShadow = [
  {
    name: "none",
  },
  {
    name: "Subtle Shadow",
  },
  {
    name: "Mid Shadow",
  },
  {
    name: "Strong Shadow",
  },
];

const imagePositions = [
    {
    name: "Full Center (Parallax)",
    value: "bgCover"
    },
    {
    name: "Fill 100% Width",
    value: "bgCover100"
    },
    {
    name: "Fill 100% Width & Height",
    value: "bgCover101"
    },
    // {
    //   name: "No Repeat",
    //   value: "bgNoRepeat"
    // },
];

const buttonStyles = [
  {
    name: "Solid",
    value: "solid"
  },
  {
    name: "Wireframe",
    value: "wireframe"
  }
];

const lineHeights = [
  {
    name: "Auto",
    value: null
  },
  {
    name: 0.7,
    value: 0.7
  },
  {
    name: 1,
    value: 1
  },
  {
    name: 1.3,
    value: 1.3
  },
  {
    name: 1.5,
    value: 1.5
  },
  {
    name: 2,
    value: 2
  }
];

export {
  fontFamilies,
  textTransforms,
  textShadow,
  imagePositions,
  buttonStyles,
  lineHeights,
};
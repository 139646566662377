<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <div class="box p-0">
    <div class="custom-editor-tab">
      <svg data-v-68a26975="" fill="none" @click="tabIndex = 0" :stroke="tabIndex === 0 ? '#2477FF' : '#A7A8AB'" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" height="24" width="24"><g data-v-68a26975=""><path data-v-68a26975="" d="M6.5,12.5,9.64,5.17a.39.39,0,0,1,.72,0L13.5,12.5"></path><line data-v-68a26975="" x1="7.91" y1="9.21" x2="12.09" y2="9.21"></line><path data-v-68a26975="" d="M.5,12.5,5.07,1.84a.57.57,0,0,1,1,0L7,3.94"></path><line data-v-68a26975="" x1="2.55" y1="7.72" x2="5" y2="7.72"></line></g></svg>
      <svg
        width="24"
        height="24"
        viewBox="0 0 19 17"
        :fill="tabIndex === 1 ? '#2477FF' : '#A7A8AB'"
        xmlns="http://www.w3.org/2000/svg"
        class="cursor-pointer"
        @click="tabIndex = 1"
      >
        <path
          d="M17 10C17 10 15 12.9062 15 14C15 15.125 15.875 16 17 16C18.0938 16 19 15.125 19 14C19 12.9062 17 10 17 10ZM16.6875 6.8125L10.1875 0.3125C10.0312 0.125 9.78125 0 9.5 0C9.25 0 8.96875 0.125 8.78125 0.3125L6.0625 3.03125L3.1875 0.15625C3 -0.03125 2.6875 -0.03125 2.46875 0.15625L2.125 0.5C1.9375 0.71875 1.9375 1.03125 2.125 1.21875L5 4.09375L1.875 7.21875C0.6875 8.40625 0.6875 10.3125 1.875 11.4688L5.53125 15.125C6.125 15.7188 6.875 16.0312 7.65625 16.0312C8.40625 16.0312 9.1875 15.7188 9.78125 15.125L16.6875 8.21875C17.0938 7.8125 17.0938 7.1875 16.6875 6.8125ZM8.71875 14.0625C8.4375 14.375 8.0625 14.5 7.65625 14.5C7.25 14.5 6.875 14.375 6.59375 14.0625L3.03125 10.5H12.2812L8.71875 14.0625ZM13.7812 9H2.53125C2.59375 8.75 2.71875 8.5 2.9375 8.28125L6.0625 5.15625L8.25 7.34375C8.53125 7.625 9 7.625 9.3125 7.34375C9.59375 7.03125 9.59375 6.5625 9.3125 6.28125L7.125 4.09375L9.5 1.71875L15.2812 7.5L13.7812 9Z"
        />
      </svg>

      <svg
        width="24"
        height="24"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="tabIndex = 2"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.78233 2.21713C3.70732 2.14212 3.60557 2.09998 3.49949 2.09998C3.3934 2.09998 3.29166 2.14212 3.21664 2.21713L1.21664 4.21713C1.06044 4.37334 1.06044 4.62661 1.21664 4.78282C1.37285 4.93903 1.62612 4.93903 1.78233 4.78282L3.09949 3.46566L3.09949 11.5343L1.78233 10.2171C1.62612 10.0609 1.37285 10.0609 1.21664 10.2171C1.06043 10.3733 1.06043 10.6266 1.21664 10.7828L3.21664 12.7828C3.29166 12.8578 3.3934 12.9 3.49949 12.9C3.60557 12.9 3.70731 12.8578 3.78233 12.7828L5.78233 10.7828C5.93854 10.6266 5.93854 10.3733 5.78233 10.2171C5.62612 10.0609 5.37285 10.0609 5.21664 10.2171L3.89949 11.5343L3.89949 3.46566L5.21664 4.78282C5.37285 4.93903 5.62612 4.93903 5.78233 4.78282C5.93854 4.62661 5.93854 4.37334 5.78233 4.21713L3.78233 2.21713ZM8.49998 3.99997C8.22383 3.99997 7.99998 4.22382 7.99998 4.49997C7.99998 4.77611 8.22383 4.99997 8.49998 4.99997H14.5C14.7761 4.99997 15 4.77611 15 4.49997C15 4.22382 14.7761 3.99997 14.5 3.99997H8.49998ZM7.99998 7.49997C7.99998 7.22382 8.22383 6.99997 8.49998 6.99997H14.5C14.7761 6.99997 15 7.22382 15 7.49997C15 7.77611 14.7761 7.99997 14.5 7.99997H8.49998C8.22383 7.99997 7.99998 7.77611 7.99998 7.49997ZM8.49998 9.99997C8.22383 9.99997 7.99998 10.2238 7.99998 10.5C7.99998 10.7761 8.22383 11 8.49998 11H14.5C14.7761 11 15 10.7761 15 10.5C15 10.2238 14.7761 9.99997 14.5 9.99997H8.49998Z"
          :fill="tabIndex == 2 ? '#2477FF' : '#A7A8AB'"
        />
      </svg>
    </div>
    <div class="custom-editor-tab-body">
      <!-- <pre>{{ templateCss }}</pre> -->
      <div v-if="tabIndex === 0">
        <h3 class="mb-4 h3">{{ editor }}</h3>
        <div class="mt-10">
          <div class="mb-10 fv-row fv-plugins-icon-container">
            <label for="" class="form-label">Font Family</label>
            <select
              class="form-select form-select-solid"
              @change="updateQuizTemplateCssData"
              v-model="font_family"
            >
              <option
                v-for="(font, index) in fontFamilies"
                :key="index"
                :value="font.name"
              >
                {{ font.name }}
              </option>
            </select>
          </div>

          <div>
            <label for="" class="form-label">Font Size</label>
            <div class="row">
              <div class="col-9 d-flex align-items-center">
                <input
                  class="form-range"
                  type="range"
                  @input="updateQuizTemplateCssData"
                  v-model="templateCss.font_size"
                  min="0"
                  max="100"
                />
              </div>
              <div class="col-3">
                <div class="custom-size">{{ templateCss.font_size }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="tabIndex === 1">
        <h3 class="mb-4 h3">{{ editor }} Colors</h3>
        <div class="mt-10">
          <div class="mt-5 mb-8">
            <label for="" class="form-label mb-3">Font Style</label>
            <div class="d-flex justify-content-between">
                <div class="d-flex">
                    <span class="form-check form-check-custom form-check-solid">
                        <input class="form-check-input w-20px h-20px" type="radio" name="text-style" id="text_solid" @change="updateQuizTemplateCssData" v-model="templateCss.style" value="solid">
                        <label class="form-label m-0 ms-4 me-4" for="text_solid">
                            Solid
                        </label>
                    </span>

                    <span class="form-check form-check-custom form-check-solid">
                        <input class="form-check-input w-20px h-20px" type="radio"
                            name="text-style" @change="updateQuizTemplateCssData" v-model="templateCss.style" id="text_gradient" value="gradient">
                        <label class="form-label m-0 ms-4 me-4" for="text_gradient">
                            Gradient
                        </label>
                    </span>
                </div>
            </div>
          </div>  

          <div class="row">
            <div class="col-lg-12" v-if="templateCss.style=='solid'">
              <div class="row">
                 <div class="col-md-6 col-xs-12 col-sm-12">
                  <label for="" class="form-label">Text Color</label>
                  <div
                    class="input-group mb-3"
                    v-click-outside="closeTextColorPicker"
                  >
                    <input
                      type="text"
                      @input="updateColor('text')"
                      class="form-control form-control-solid"
                      v-model="textColor.hex8"
                    />
                    <div
                      class="input-group-append"
                      @click="isTextColorPicker = !isTextColorPicker"
                    >
                      <span class="input-group-text" id="basic-addon2">
                        <div class="bg-white">
                          <div
                            class="rect"
                            :style="{ backgroundColor: textColor.hex8 }"
                          ></div>
                        </div>
                      </span>
                    </div>
                    <Sketch
                      :class="{ colorpicker: true, 'd-none': !isTextColorPicker }"
                      v-model="textColor"
                      :preset-colors="[
                        '#ffffff',
                        '#efefef',
                        '#000000',
                        '#201652',
                        '#0075B2',
                        '#FFE300',
                        '#42B99F',
                        '#CC0000',
                      ]"
                    ></Sketch>
                  </div>
                </div>
                <!-- <div class="col-md-6 col-xs-12 col-sm-12">
                  <label for="" class="form-label">Bold Color</label>
                  <div
                    class="input-group mb-3"
                    v-click-outside="closeBoldColorPicker"
                  >
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      v-model="boldColor.hex8"
                      @input="updateColor('bold')"
                    />
                    <div
                      class="input-group-append"
                      @click="isBoldColorPicker = !isBoldColorPicker"
                    >
                      <span class="input-group-text" id="basic-addon2">
                        <div class="bg-white">
                          <div
                            class="rect"
                            :style="{ backgroundColor: boldColor.hex8 }"
                          ></div>
                        </div>
                      </span>
                    </div>
                    <Sketch
                      :class="{ colorpicker: true, 'd-none': !isBoldColorPicker }"
                      v-model="boldColor"
                      :preset-colors="[
                        '#ffffff',
                        '#efefef',
                        '#000000',
                        '#201652',
                        '#0075B2',
                        '#FFE300',
                        '#42B99F',
                        '#CC0000',
                      ]"
                    ></Sketch>
                  </div>
                </div> -->
              </div>
            </div>
           

            <div class="col-lg-12" v-if="templateCss.style=='gradient'">
                <div class="row mt-5">
                    <div class="col-md-6 col-xs-12 col-sm-12">
                        <label for="" class="form-label">Gradient Color</label>
                        <div class="input-group mb-3" v-click-outside="closeGradient1ColorPicker">
                            <input type="text" class="form-control form-control-solid" @input="updateColor('gradient1')" v-model="gradientColor1.hex8">
                            <div class="input-group-append" @click="isGradient1ColorPicker = !isGradient1ColorPicker">
                                <span class="input-group-text" id="basic-addon2">
                                    <div class="bg-white">
                                        <div class="rect" :style="{ backgroundColor: gradientColor1.hex8 }"></div>
                                    </div>
                                </span>
                            </div>
                            <Sketch
                                :class="{ colorpicker: true, 'd-none': !isGradient1ColorPicker }"
                                v-model="gradientColor1"
                                :preset-colors="['#ffffff','#efefef','#000000','#201652','#0075B2','#FFE300','#42B99F','#CC0000']"
                            ></Sketch>
                        </div>
                    </div>  

                    <div class="col-md-6 col-xs-12 col-sm-12">
                        <label for="" class="form-label">Stop 1</label>
                        <div class="row">
                            <div class="col-9 d-flex align-items-center">
                                <input class="form-range" type="range" @change="updateQuizTemplateCssData" v-model="templateCss.gradient_color_stop_1" min="0" max="100" />
                            </div>
                            <div class="col-3 ps-0">
                                <div class="custom-size">{{templateCss.gradient_color_stop_1 }} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6 col-xs-12 col-sm-12">
                        <label for="" class="form-label">Gradient Color</label>
                        <div class="input-group mb-3" v-click-outside="closeGradient2ColorPicker">
                            <input type="text" class="form-control form-control-solid" @input="updateColor('gradient2')" v-model="gradientColor2.hex8">
                            <div class="input-group-append" @click="isGradient2ColorPicker = !isGradient2ColorPicker">
                                <span class="input-group-text" id="basic-addon2">
                                    <div class="bg-white">
                                        <div class="rect" :style="{ backgroundColor: gradientColor2.hex8 }"></div>
                                    </div>
                                </span>
                            </div>
                            <Sketch
                                :class="{ colorpicker: true, 'd-none': !isGradient2ColorPicker }"
                                v-model="gradientColor2"
                                :preset-colors="['#ffffff','#efefef','#000000','#201652','#0075B2','#FFE300','#42B99F','#CC0000']"
                            ></Sketch>
                        </div>
                    </div>  

                    <div class="col-md-6 col-xs-12 col-sm-12">
                        <label for="" class="form-label">Stop 2</label>
                        <div class="row">
                            <div class="col-9 d-flex align-items-center">
                                <input class="form-range" type="range" @change="updateQuizTemplateCssData" v-model="templateCss.gradient_color_stop_2" min="0" max="100" />
                            </div>
                            <div class="col-3 ps-0">
                                <div class="custom-size">{{templateCss.gradient_color_stop_2 }} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-12 col-xs-12 col-sm-12">
                        <label for="" class="form-label">Angle</label>
                        <div class="row">
                            <div class="col-9 d-flex align-items-center">
                                <input class="form-range" type="range" @change="updateQuizTemplateCssData" v-model="templateCss.gradient_angle" min="0" max="360" />
                            </div>
                            <div class="col-3">
                                <div class="custom-size">{{ templateCss.gradient_angle ? templateCss.gradient_angle : '0' }} deg</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>

          <hr style="background-color: gray" class="my-5" />

          <div class="row" v-show="editorType == 'progress'">
            <div class="col-md-6 col-xs-12 col-sm-12">
              <label for="" class="form-label">Bar Color</label>
              <div
                class="input-group mb-3"
                v-click-outside="closeBarColorPicker"
              >
                <input
                  type="text"
                  class="form-control form-control-solid"
                  v-model="barColor.hex8"
                  @input="updateColor('bar')"
                />
                <div
                  class="input-group-append"
                  @click="isBarColorPicker = !isBarColorPicker"
                >
                  <span class="input-group-text" id="basic-addon2">
                    <div class="bg-white">
                      <div
                        class="rect"
                        :style="{ backgroundColor: barColor.hex8 }"
                      ></div>
                    </div>
                  </span>
                </div>
                <Sketch
                  :class="{ colorpicker: true, 'd-none': !isBarColorPicker }"
                  v-model="barColor"
                  :preset-colors="[
                    '#ffffff',
                    '#efefef',
                    '#000000',
                    '#201652',
                    '#0075B2',
                    '#FFE300',
                    '#42B99F',
                    '#CC0000',
                  ]"
                ></Sketch>
              </div>
            </div>

            <div class="col-md-6 col-xs-12 col-sm-12">
              <label for="" class="form-label">Active Color</label>
              <div
                class="input-group mb-3"
                v-click-outside="closeActiveColorPicker"
              >
                <input
                  type="text"
                  class="form-control form-control-solid"
                  v-model="activeColor.hex8"
                  @input="updateColor('active')"
                />
                <div
                  class="input-group-append"
                  @click="isActiveColorPicker = !isActiveColorPicker"
                >
                  <span class="input-group-text" id="basic-addon2">
                    <div class="bg-white">
                      <div
                        class="rect"
                        :style="{ backgroundColor: activeColor.hex8 }"
                      ></div>
                    </div>
                  </span>
                </div>
                <Sketch
                  :class="{ colorpicker: true, 'd-none': !isActiveColorPicker }"
                  v-model="activeColor"
                  :preset-colors="[
                    '#ffffff',
                    '#efefef',
                    '#000000',
                    '#201652',
                    '#0075B2',
                    '#FFE300',
                    '#42B99F',
                    '#CC0000',
                  ]"
                ></Sketch>
              </div>
            </div>

            <div class="col-md-6 col-xs-12 col-sm-12 mt-6">
              <label for="" class="form-label">Icon Color</label>
              <div
                class="input-group mb-3"
                v-click-outside="closeIconColorPicker"
              >
                <input
                  type="text"
                  class="form-control form-control-solid"
                  v-model="iconColor.hex8"
                  @input="updateColor('icon')"
                />
                <div
                  class="input-group-append"
                  @click="isIconColorPicker = !isIconColorPicker"
                >
                  <span class="input-group-text" id="basic-addon2">
                    <div class="bg-white">
                      <div
                        class="rect"
                        :style="{ backgroundColor: iconColor.hex8 }"
                      ></div>
                    </div>
                  </span>
                </div>
                <Sketch
                  :class="{ colorpicker: true, 'd-none': !isIconColorPicker }"
                  v-model="iconColor"
                  :preset-colors="[
                    '#ffffff',
                    '#efefef',
                    '#000000',
                    '#201652',
                    '#0075B2',
                    '#FFE300',
                    '#42B99F',
                    '#CC0000',
                  ]"
                ></Sketch>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="tabIndex === 2">
        <h3 class="mb-10 h3">Spacing</h3>
        <div class="mb-7">
          <label for="" class="form-label">Spacing Before</label>
          <div class="row">
            <div class="col-9 d-flex align-items-center">
              <input
                class="form-range"
                type="range"
                @input="updateQuizTemplateCssData"
                v-model="templateCss.spacing_before"
                min="0"
                max="100"
              />
            </div>
            <div class="col-3">
              <div class="custom-size">{{ templateCss.spacing_before }}</div>
            </div>
          </div>
        </div>
        <div class="mb-7">
          <label for="" class="form-label">Spacing After</label>
          <div class="row">
            <div class="col-9 d-flex align-items-center">
              <input
                class="form-range"
                type="range"
                @input="updateQuizTemplateCssData"
                v-model="templateCss.spacing_after"
                min="0"
                max="100"
              />
            </div>
            <div class="col-3">
              <div class="custom-size">{{ templateCss.spacing_after }}</div>
            </div>
          </div>
        </div>
        <!-- <div class="mb-7">
          <label for="" class="form-label">Line Height</label>
          <div class="row">
            <div class="col-9 d-flex align-items-center">
              <input
                class="form-range"
                type="range"
                @input="updateQuizTemplateCssData"
                v-model="templateCss.line_height"
                min="0"
                max="100"
              />
            </div>
            <div class="col-3">
              <div class="custom-size">{{ templateCss.line_height }}</div>
            </div>
          </div>
        </div> -->
         <div class="mb-7">
            <label for="" class="form-label">Line Height</label>
            <select class="form-select form-select-solid" v-model="templateCss.line_height" @input="updateQuizTemplateCssData">
                <option
                    v-for="(l, index) in lineHeights"
                    :key="index"
                    :value="l.value"
                >
                    {{ l.name }} {{index > 0 ? 'em' : ''}}
                </option>
            </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, toRefs } from "vue";
import { Sketch } from "@ckpack/vue-color";
import vClickOutside from "click-outside-vue3";
import { fontFamilies, imagePositions,lineHeights } from "@/core/helpers/staticData";

export default defineComponent({
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    editor_type: {
      type: String,
    },
    template_css: {
      type: Object,
    },
  },
  components: { Sketch },
  setup(props) {
    const { editor_type: editorType } = toRefs(props);
    const { template_css: templateCss } = toRefs(props);
    return { editorType, templateCss };
  },
  data() {
    return {
      tabIndex: 0,
      bgColor: {},
      colors: "#194D33A8",
      isBgColorPicker: false,
      imagePositions: imagePositions,
      full_page_uploaded_image: null,
      full_page_uploaded_image_url: null,
      fontFamilies: fontFamilies,
      lineHeights: lineHeights,
      fontSize: 25,
      isTextColorPicker: false,
      isBoldColorPicker: false,
      isBarColorPicker: false,
      isActiveColorPicker: false,
      isIconColorPicker: false,
      textColor: {},
      boldColor: {},
      barColor: {},
      activeColor: {},
      iconColor: {},
      isGradient1ColorPicker : false,
      gradientColor1 : {},
      isGradient2ColorPicker : false,
      gradientColor2 : {},
      apiCallStatus : false
    };
  },
  computed: {
    editor() {
      console.log("editorType", this.editorType);
      if (this.editorType == "headline") return "Main Headline";
      else if (this.editorType == "description") return "Description";
      else if (this.editorType == "progress") return "Progress Text";
      else if (this.editorType == "action_btn") return "Action Button Text";
      else if (this.editorType == "single_splash") return "Splash Text";
      else if (this.editorType == "multi_splash") return "Splash Text";
    },
    font_family: {
      get() {
        return this.templateCss.font_family;
      },
      set(newValue) {
        this.templateCss.font_family = newValue;
      },
    },
  },
  watch: {
    textColor() {
      this.updateColor("text");
    },
    boldColor() {
      this.updateColor("bold");
    },
    barColor() {
      if (this.editorType == "progress") this.updateColor("bar");
    },
    activeColor() {
      if (this.editorType == "progress") this.updateColor("active");
    },
    iconColor() {
      if (this.editorType == "progress") this.updateColor("icon");
    },
    gradientColor1(){
      this.updateColor('gradient1')
    },
    gradientColor2(){
      this.updateColor('gradient2')
    },
    editorType(){
      this.tabIndex = 0
    }
  },
  mounted() {
    this.tabIndex = 0;
    this.setupColors();
    this.updateApiCallStatus()
  },
  methods: {
    setupColors() {
      if (this.templateCss && this.templateCss.text_color) {
        let textData = {
          hex8: this.templateCss.text_color,
        };
        this.textColor = textData;
      }
      if (this.templateCss && this.templateCss.bold_color) {
        let boldData = {
          hex8: this.templateCss.bold_color,
        };
        this.boldColor = boldData;
      }
      if (this.templateCss && this.templateCss.bar_color) {
        let barData = {
          hex8: this.templateCss.bar_color,
        };
        this.barColor = barData;
      }
      if (this.templateCss && this.templateCss.active_color) {
        let activeData = {
          hex8: this.templateCss.active_color,
        };
        this.activeColor = activeData;
      }
      if (this.templateCss && this.templateCss.gradient_color_1) {
          let gradient1Data = {
              hex8: this.templateCss.gradient_color_1
          }
          this.gradientColor1 = gradient1Data
      }
      if (this.templateCss && this.templateCss.gradient_color_2) {
          let gradient2Data = {
              hex8: this.templateCss.gradient_color_2
          }
          this.gradientColor2 = gradient2Data
      }
      if (this.templateCss && this.templateCss.icon_color) {
          let iconColorData = {
              hex8: this.templateCss.icon_color
          }
          this.iconColor = iconColorData
      }
    },
    updateColor(type) {
      if(this.apiCallStatus){
        if (type == "text") {
          this.templateCss.text_color = this.textColor.hex8;
        } else if (type == "bold") {
          this.templateCss.bold_color = this.boldColor.hex8;
        } else if (type == "bar") {
          this.templateCss.bar_color = this.barColor.hex8;
        } else if (type == "active") {
          this.templateCss.active_color = this.activeColor.hex8;
        }else if (type == 'gradient1') {
          this.templateCss.gradient_color_1 = this.gradientColor1.hex8
        } else if (type == 'gradient2') {
          this.templateCss.gradient_color_2 = this.gradientColor2.hex8
        } else if (type == 'icon') {
          this.templateCss.icon_color = this.iconColor.hex8
        }
        this.updateQuizTemplateCssData();
      }
    },
    closeTextColorPicker() {
      this.isTextColorPicker = false;
    },
    closeBoldColorPicker() {
      this.isBoldColorPicker = false;
    },
    closeBarColorPicker() {
      this.isBarColorPicker = false;
    },
    closeActiveColorPicker() {
      this.isActiveColorPicker = false;
    },
    closeIconColorPicker() {
      this.isIconColorPicker = false;
    },
    updateQuizTemplateCssData: _.debounce(function () {
      this.$emit("updateQuizTemplateCss", this.editorType);
    }, 1000),
    closeGradient1ColorPicker(){
        this.isGradient1ColorPicker = false
    },
    closeGradient2ColorPicker(){
        this.isGradient2ColorPicker = false
    },
    updateApiCallStatus(){
      setTimeout(() => {
          this.apiCallStatus = true
      }, 200);
    }
  },
});
</script>

<style scoped>
.box {
  background-color: #2d2f3a;
  border-radius: 5px;
  padding: 15px;
}

.custom-editor-tab {
  color: lightgrey;
  padding: 13px;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-around;
}
.custom-editor-tab-body {
  background-color: #343542;
  height: 93.2%;
  border-radius: 0 0 5px 5px;
  padding: 20px 15px;
  min-height: calc(100vh - 260px);
}
.custom-editor-tab-body h3 {
  color: white;
  font-weight: 400;
}
.custom-tabs {
  color: lightgrey;
  display: flex;
  align-items: center;
  gap: 15px;
}

.custom-tabs .active {
  color: white;
  font-weight: 600;
}

.action-box {
  display: flex;
  align-items: center;
  gap: 40px;
}

.form-check {
  min-height: 0;
}

.vc-input__input {
  color: #202020 !important;
}

.rect {
  width: 23px;
  height: 23px;
}
.input-group-text {
  border: 0;
  background-color: #2d2f3a;
  border-radius: 0 0.475rem 0.475rem 0;
}

.image-block {
  height: 90px;
  border-radius: 6px;
  position: relative;
}

.image-block-edit-icon {
  position: absolute;
  color: #11132d;
  right: 10px;
  background: white;
  font-size: 16px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  padding-left: 6px;
  padding-top: 6px;
  top: 7px;
}

.image-radious {
  border-radius: 10px;
}

.bgCover101 {
  background-size: 100% 100% !important;
  -webkit-background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}

.bgCover {
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-attachment: fixed !important;
  background-repeat: repeat repeat !important;
  background-position: center center;
}

.bgCover100 {
  background-size: 100% auto !important;
  -webkit-background-size: 100% auto !important;
  background-repeat: no-repeat !important;
}

.bgNoRepeat {
  background-repeat: no-repeat !important;
}

.custom-size {
  background-color: #2d2f3a;
  border-radius: 4px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.form-label{
    color: #e4e6ef;
}
.form-check.form-check-solid .form-check-input {
    border: 0;
    background-color: #3d3f4c;
}
.form-check.form-check-solid .form-check-input:checked {
    background-color: #e49c35;
}
.form-control.form-control-solid, .form-select-solid {
    background-color: #3d3f4c;
    border-color: #3d3f4c;
    color: #c4c7cc;
    transition: color .2s ease,background-color .2s ease;
}
.dropdown.show > .form-select.form-select-solid, .form-select.form-select-solid:active, .form-select.form-select-solid.active, .form-select.form-select-solid:focus, .form-select.form-select-solid.focus {
    background-color: #3d3f4c;
    border-color: #3d3f4c;
    color: #c4c7cc;
    transition: color 0.2s ease, background-color 0.2s ease;
}
.vc-sketch{
    position: absolute;
    z-index: 10000;
}
</style>
